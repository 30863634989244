const BASE_URL = {
    production: "https://api.mookie.app",
    staging: "https://api-test.mookie.app",
    dev: "https://api-test.mookie.app",
};

export const MIXPANEL_KEY = {
    dev: typeof process !== 'undefined' ? process.env.REACT_APP_MIXPANEL_KEY_DEV : null,
    staging: typeof process !== 'undefined' ? process.env.REACT_APP_MIXPANEL_KEY_STAGING : null,
    production: typeof process !== 'undefined' ? process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION : null
};

  
export default BASE_URL;