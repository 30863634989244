export const GET_FOOTBALL_COUNTRIES = 'GET_FOOTBALL_COUNTRIES';
export const GET_FOOTBALL_LEAGUES = 'GET_FOOTBALL_LEAGUES';
export const GET_FOOTBALL_UPCOMING_FIXTURES = 'GET_FOOTBALL_UPCOMING_FIXTURES';
export const GET_FOOTBALL_LIVE_FIXTURES = 'GET_FOOTBALL_LIVE_FIXTURES';
export const GET_FOOTBALL_FINISHED_FIXTURES = 'GET_FOOTBALL_FINISHED_FIXTURES';
export const GET_FOOTBALL_TEAM_STATS = 'GET_FOOTBALL_TEAM_STATS';
export const GET_FOOTBALL_H2H = 'GET_FOOTBALL_H2H';
export const GET_FOOTBALL_TEAM_FIXTURES = 'GET_FOOTBALL_TEAM_FIXTURES';
export const SEARCH_FOOTBALL_LEAGUES = 'SEARCH_FOOTBALL_LEAGUES';
export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_SUPPORTED_LEAGUES = 'GET_SUPPORTED_LEAGUES';


