import {
  GET_FOOTBALL_COUNTRIES,
  GET_FOOTBALL_LEAGUES,
  GET_SUPPORTED_LEAGUES,
  GET_FOOTBALL_UPCOMING_FIXTURES,
  GET_FOOTBALL_LIVE_FIXTURES,
  GET_FOOTBALL_FINISHED_FIXTURES,
  GET_FOOTBALL_TEAM_STATS,
  GET_FOOTBALL_H2H,
  GET_FOOTBALL_TEAM_FIXTURES,
  SEARCH_FOOTBALL_LEAGUES,
  GET_FAVORITES,
} from "../types/sportsTypes";
import { LOGOUT_USER } from "../types/authTypes";

const initialState = {
  football: {
    countries: [],
    leagues: {},
    supported_leagues: [],
    fixtures: {
      upcoming: [],
      live: [],
      finished: []
    },
    team_stats: {},
    team_fixtures: {},
    h2h: {},
    searched_leagues: []
  },
  favorites: {},
};

const sportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FOOTBALL_COUNTRIES:
      return {
        ...state,
        football: {
          ...state.football,
          countries: action.payload
        }
      };

    case GET_FOOTBALL_LEAGUES:
      return {
        ...state,
        football: {
          ...state.football,
          leagues: {
            ...state.football.leagues,
            [action.payload.countryName]: action.payload.leagues
          }
        }
      };
    
    case GET_SUPPORTED_LEAGUES:
      return {
        ...state,
        football: {
          ...state.football,
          supported_leagues: [...state.football.supported_leagues, ...action.payload]
        }
      };

    case SEARCH_FOOTBALL_LEAGUES:
      return {
        ...state,
        football: {
          ...state.football,
          searched_leagues: action.payload
        }
      };

    case GET_FOOTBALL_UPCOMING_FIXTURES:
      return {
        ...state,
        football: {
          ...state.football,
          fixtures: {
            ...state.football.fixtures,
            upcoming: action.payload
          }
        }
      };

    case GET_FOOTBALL_LIVE_FIXTURES:
      return {
        ...state,
        football: {
          ...state.football,
          fixtures: {
            ...state.football.fixtures,
            live: action.payload
          }
        }
      };

    case GET_FOOTBALL_FINISHED_FIXTURES:
      return {
        ...state,
        football: {
          ...state.football,
          fixtures: {
            ...state.football.fixtures,
            finished: action.payload
          }
        }
      };
    
    case GET_FOOTBALL_TEAM_STATS:
      return {
        ...state,
        football: {
          ...state.football,
          team_stats: {
            ...state.football.team_stats,
            [action.payload.team_id]: action.payload,
          }
        }
      };

    case GET_FOOTBALL_H2H:
      const h2hId = `${action.payload.team1Id}-${action.payload.team2Id}`
      return {
        ...state,
        football: {
          ...state.football,
          h2h: {
            ...state.football.h2h,
            [h2hId]: action.payload.data,
          }
        }
      };
    
    case GET_FOOTBALL_TEAM_FIXTURES:
      return {
        ...state,
        football: {
          ...state.football,
          team_fixtures: {
            ...state.football.team_fixtures,
            [action.payload.teamId]: action.payload.data,
          }
        }
      };

    case GET_FAVORITES:
      return {
        ...state,
        favorites: action.payload,
      };

    case LOGOUT_USER:
      return initialState;

    default:
      return state;
  }
};

export default sportsReducer;
