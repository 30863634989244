// import localStorage from "redux-persist/es/storage";
// import { history } from "../../utils/history";
import { LOGOUT_USER, LOGGED_IN_USER, UPDATE_USER_AVATAR, DELETE_ACCOUNT, SET_REACTIVATED, UPDATE_USER_COINS, UPDATE_PROFILE, VERIFY_ID } from "../types/authTypes";
import { IS_LOADING_TRUE, IS_LOADING_FALSE, } from "../types/loadingTypes";
// import { setCookie, removeCookie } from "../../utils/storage";
import handleError from "../../utils/handleError";
import { postApi,putApi,deleteApi,patchApi, getApi } from "../../utils/reqClient";
import { toastError, toastSuccess } from "../../components/ui/Toast";
import SSEService from '../../services/SSEService';

export const login = (params, history) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await postApi(`auth/login`, params);
    if (response.data.success === true) {
      const { data } = response.data;
      localStorage.setItem("_mt_", data.token);
      dispatch({ type: LOGGED_IN_USER, payload: data });
      dispatch({ type: IS_LOADING_FALSE });
      const { reactivated } = data;
      if(reactivated){
        dispatch({ type: SET_REACTIVATED });
      }
      //console.log("creating connection with token - " + data.token);
      //const sseService = new SSEService(dispatch);
      //sseService.establishConnection(data.token);
      return history.push("/home");
    }
    dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};

export const signUp = (body) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await postApi(`auth/register`, body);
    if (response.data.success === true) {
      const { data } = response.data;
      toastSuccess(response.data.message || "Success!");
      dispatch({ type: IS_LOADING_FALSE });
      return { status: 200 };
    }
    dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
    return { status: 500, error: error.message };
  }
};


export const CheckUsername = (username, setState) => async (dispatch) => {
  try {
    const response = await getApi(`others/username/${username}`);
    if (response.data.success === true) {
      return setState(1);
    }
    setState(2);
  } catch (error) {
    // handleError(error);
    setState(2);
  }
};

export const CheckChannelName = (channelName, setState) => async (dispatch) => {
  try {
    const response = await getApi(`others/channelName/${channelName}`);
    if (response.data.success === true) {
      return setState(1);
    }
    setState(2);
  } catch (error) {
    // handleError(error);
    setState(2);
  }
};

export const searchUsernames = (query, exactMatch = false) => async (dispatch) => {
  try {
    let response;
    
    if(exactMatch)
      response = await getApi(`others/searchUsernames?query=${query}&limit=5&match=true`);
    else
      response = await getApi(`others/searchUsernames?query=${query}&limit=5`);

    if (response.data.success === true) {
      return response.data.data;
    } else {
      return [];
    }

  } catch (error) {
    // handleError(error);
    return [];
  }
};

export const verifyEmail = (token) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await patchApi(`auth/verify?token=${token}`);
    if (response.data.success === true) {
      toastSuccess('Email successfully verified.')
    }
    dispatch({ type: IS_LOADING_FALSE });
    return { status: 200 };
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
    return { status: 500 };
  }
};

export const getReferredEmail = (referred) => async (dispatch) => {
  try {
    const response = await patchApi(`auth/getReferred?referred=${referred}`);
    if (response.data.success === true) {
      return { status: 200, success: true, email: response.data.data.email };
    } else {
      return { status:200, success: false };
    }
  } catch (error) {
    handleError(error);
    return { status: 500 };
  }
};

export const checkPromotedEmail = (referral_code, email) => async (dispatch) => {
  try {
    const response = await postApi(`auth/check-waitlist`, {referral_code, email});
    if (response.data.success === true) {
      return { status: 200, success: true };
    } else {
      return { status:200, success: false };
    }
  } catch (error) {
    handleError(error);
    return { status: 500 };
  }
};


export const verifyReferralEmail = (body, history) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await postApi(`auth/confirm-referrer`, body);
    if (response.data.success === true) {
      const { message } = response.data;
      toastSuccess(message);
      dispatch({ type: IS_LOADING_FALSE });
      return 
    }
    dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};

export const EditUserProfile = (body,setEditProfile) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await putApi(`profiles`, body);
    if (response.data.success === true) {
      const { data } = response.data;
      toastSuccess('User profile updated successfully')
      dispatch({ type: UPDATE_PROFILE, payload: body });
      dispatch({ type: IS_LOADING_FALSE });
      if(setEditProfile){
        await GetUserCoins()(dispatch);
        return setEditProfile(false);
      } else {
        await GetUserCoins()(dispatch);
        return dispatch({type: UPDATE_USER_AVATAR, payload: body.photo_url})
      }
    } else {
      toastError(response.data.message || "Update Failed");
    }
    dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};

export const GetUserCoins = () => async (dispatch) => {
  try {
    //console.log('attempting to get user profile');
    // Set loading to true before endpoint call
    //dispatch({ type: IS_LOADING_TRUE });
    const response = await getApi(`profiles`);
    if (response.data.success === true) {
      const { data } = response.data;
      dispatch({ type: UPDATE_USER_COINS, payload: { coins: data?.coins || 0, experience: data?.experience_points || 0, identity: data?.identity || {} }});
      //dispatch({ type: IS_LOADING_FALSE });
    }
    //dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    console.error('failed to get user profile - ' + JSON.stringify(error));
    //dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};

export const DeleteUser = (close,password) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await deleteApi(`profiles`,password);
    if (response.data.success === true) {
      dispatch({ type: IS_LOADING_FALSE });
      close();
      dispatch({type: DELETE_ACCOUNT});
      // toastSuccess('Account deactivated successfully');
      return 
    }
    dispatch({ type: IS_LOADING_FALSE });
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};

export const forgotPassword = (body) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await patchApi(`auth/password`, body);
    if (response.data.success === true) {
      toastSuccess('OTP sent successfully');
      localStorage.setItem("otp", response.data.data.otp);
      return dispatch({ type: IS_LOADING_FALSE });
    }
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
  }
};

export const confirmForgotPassword = (body,history) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await putApi(`auth/password`, body);
    if (response.data.success === true) {
      toastSuccess('Your password has been successfully changed');
      localStorage.removeItem("otp");
      history.push("/")
      dispatch({ type: IS_LOADING_FALSE });
      return { status: 200 };
    }
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
    return { status: 400, error: error.message };
    // localStorage.removeItem("otp");
  }
};


export const changePassword = (body,resetForm) => async (dispatch) => {
  try {
    // Set loading to true before endpoint call
    dispatch({ type: IS_LOADING_TRUE });
    const response = await postApi(`auth/password`, body);
    if (response.data.success === true) {
      toastSuccess('Password changed successfully');
      resetForm();
      dispatch({ type: IS_LOADING_FALSE });
      return true;
    } else {
      toastError("Password change failed");
      return false;
    }
  } catch (error) {
    // Set loading to false after endpoint call is not successful
    dispatch({ type: IS_LOADING_FALSE });
    handleError(error);
    return false;
  }
};

export const getUserProfile = (userId) => async (dispatch) => {
  try {
    const response = await getApi(`profiles/user/${userId}`);
    if (response.data.success === true) {
      return { status: 200, success: true, data: response.data.data };
    } else {
      return { status:200, success: false, data: [] };
    }
  } catch (error) {
    handleError(error);
    return { status: 500, success: false };
  }
};

export const signOut = () => (dispatch) => {
  // Set global loading to false
  dispatch({ type: IS_LOADING_FALSE });
  localStorage.removeItem("_mt_");
  localStorage.removeItem("persist:root");
  //const sseService = new SSEService(dispatch);
  //sseService.closeConnection();
  dispatch({ type: LOGOUT_USER });
  // history.replace("/");
};

export const signUpViaGoogle = (code) => async (dispatch) => {
  try {
    const response = await getApi(`auth/google-signup/?code=${code}`);
    if (response.data.success === true) {
      return response.data.data;
    }
    toastError('Sign Up Via Google Failed');
    return null;
  } catch (error) {
    handleError(error);
  }
};

// Action to request ID verification
export const verifyID = (idType, file) => async (dispatch) => {
  try {
    // Set loading to true before making the API call
    dispatch({ type: IS_LOADING_TRUE });
    
    // Create form data for the file upload
    const formData = new FormData();
    formData.append('file', file);
    formData.append('idType', idType);

    // Make the API call to request ID validation
    const response = await postApi('profiles/request-id-validation', formData);

    // Check if the response was successful
    if (response.data.success === true) {
      const { message, data: verification } = response.data; // updated to reflect the new response structure

      // Dispatch action to update verification data
      dispatch({ type: IS_LOADING_FALSE });
      dispatch({ type: 'VERIFY_ID', payload: verification });

      // Return a success message
      //toastSuccess(message);
      return { success: true, message };
    } else {
      // Handle failure case
      dispatch({ type: IS_LOADING_FALSE });
      toastError('ID verification request failed. Please try again.');
      return { success: false, message: 'ID verification request failed.' };
    }
  } catch (error) {
    // If the API call fails, stop loading and handle error
    dispatch({ type: IS_LOADING_FALSE });
    
    // Handle the error globally
    handleError(error);

    // Return failure status with the error message
    const errorMessage = error.response?.data?.message || 'An error occurred during the ID verification process.';
    return { success: false, message: errorMessage };
  }
};


// export const createPassword = (params, showModal) => async (dispatch) => {
//   try {
//     // Set loading to true before endpoint call
//     dispatch({ type: IS_LOADING_TRUE });
//     const response = await postApi(
//       `v1/auth/accounts/recovery/${params.id}/confirm/${params.token}`,
//       {
//         password: params.password,
//         confirmPassword: params.password
//       }
//     );
//     if (response.data.success === true) {
//       showModal(`#ForgotPasswordSuccessModal`);
//       history.push("/");
//       return dispatch({ type: IS_LOADING_FALSE });
//     }
//   } catch (error) {
//     // Set loading to false after endpoint call is not successful
//     dispatch({ type: IS_LOADING_FALSE });
//     handleError(error);
//   }
// };

// export const changePassword = (body, showModal) => async (dispatch) => {
//   try {
//     // Set loading to true before endpoint call
//     dispatch({ type: IS_LOADING_TRUE });
//     const response = await postApi(`v1/auth/changepassword`, body);
//     if (response.data.success === true) {
//       showModal(`#ChangePasswordSuccessModal`);
//       return dispatch({ type: IS_LOADING_FALSE });
//     }
//     dispatch({ type: IS_LOADING_FALSE });
//   } catch (error) {
//     // Set loading to false after endpoint call is not successful
//     dispatch({ type: IS_LOADING_FALSE });
//     handleError(error);
//   }
// };
