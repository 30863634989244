export const UPDATE_CARD_DETAILS = 'UPDATE_CARD_DETAILS';
export const FETCH_CARD_DETAILS = 'FETCH_CARD_DETAILS';
export const MAKE_CARD_PRIMARY = 'MAKE_CARD_PRIMARY';
export const DELETE_CARD = "DELETE_CARD";
export const VALIDATE_BANK_ACCOUNT = "VALIDATE_BANK_ACCOUNT";
export const FETCH_BANK_ACCOUNTS = "FETCH_BANK_ACCOUNTS";
export const SET_BANK_PRIMARY = "SET_BANK_PRIMARY";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const FETCH_BANKS = "FETCH_BANKS";
export const FETCH_PAYOUTS = "FETCH_PAYOUTS";
export const SUCCESSFUL_PURCHASE = "SUCCESSFUL_PURCHASE";
export const PURCHASE_FAILURE = "PURCHASE_FAILURE";
export const PURCHASE_RETRY_COUNT = "PURCHASE_RETRY_COUNT";